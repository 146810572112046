<template>
  <form @submit="handleSubmit"
        class="flex flex-col items-center w-full lg:items-end"
  >
    <SectionNewsletterFormInput v-model="inputEmail"
                                :state="state"
                                :label="inputLabel"
                                :placeholder="inputPlaceholder"
                                class="sm:w-96"
    >
      <SectionNewsletterFormButton :state="state"
                                   :label="submitLabel"
      />
    </SectionNewsletterFormInput>

    <SectionNewsletterFormCheckbox :state="state"
                                   :copy="consentLabel"
    />

    <SectionNewsletterFormToast :state="state"
                                :success-message="successMessage"
                                :error-message="errorMessage"
    />
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useNewsletter from '../../../composables/use-newsletter';

interface FormToastProps {
  sendinblueListId: number;
  inputPlaceholder: string;
  inputLabel: string;
  submitLabel: string;
  consentLabel: string;
  successMessage: string;
  errorMessage: string;
}

const props = defineProps<FormToastProps>();
const { subscribe, state } = useNewsletter();

const inputEmail = ref('');

async function handleSubmit(e: Event) {
  e.preventDefault();

  subscribe(inputEmail.value, props.sendinblueListId).then(() => {
    inputEmail.value = '';
  }).catch(() => {
    console.log('Subscribe failed');
  });
}
</script>
